import React from "react"
// import { Link } from "gatsby"
import { Link } from "gatsby-theme-material-ui";
import { 
  useStaticQuery, 
  graphql 
} from "gatsby"
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Layout from "../layout/layout"
import SEO from "../seo/seo"

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 120,
    paddingBottom: 50
  }
}));

const Contact = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query ContactQuery {
      contentfulBrand {
        companyName
        phoneNumber
        address
        email
        companyMap {
          companyMap
        }
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  function Iframe(props) {
    return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }

  const iframe = `<iframe 
      title="company-google-map"
      src=${data.contentfulBrand.companyMap.companyMap} 
      width="100%" 
      height="450" 
      frameborder="0" 
      allowfullscreen="" 
      aria-hidden="false"
    >
  </iframe>`
  
  return (
    <Layout>
      <SEO 
        title={`Contact | ${data.site.siteMetadata.title}`}
      />
      <Container 
        wrap="wrap" 
        className={classes.root}
      >
        <Typography 
          variant="h2" 
        >
          Contact
        </Typography>
        <Grid container direction="row" spacing={3}>
          <Grid item md={4} sm={6} xl={4} xs={12}>
            {
              data.contentfulBrand.address && (
                <Box my={3}>
                  <Typography 
                    variant="subtitle1" 
                    component="p"
                  >
                    Address
                  </Typography>
                  <Typography 
                    variant="subtitle2" 
                    component="p"
                  >
                    {data.contentfulBrand.address}
                  </Typography>
                </Box>
              )
            }
            {
              data.contentfulBrand.email && (
                <Box my={3}>
                  <Typography variant="subtitle1" component="p">
                    Email
                  </Typography>
                  <Link to={`mailto:${data.contentfulBrand.email}`}>
                    <Typography variant="subtitle2" component="p" color="textPrimary">
                      {data.contentfulBrand.email}
                    </Typography>
                  </Link>
                </Box>
              )
            }
            {
              data.contentfulBrand.phoneNumber && (
                <Box my={3}>
                  <Typography variant="subtitle1" component="p">
                    Phone Number
                  </Typography>
                  <Link to={`tel:${data.contentfulBrand.phoneNumber}`}>
                    <Typography variant="subtitle2" component="p" color="textPrimary">
                      {data.contentfulBrand.phoneNumber}
                    </Typography>
                  </Link>
                </Box>
              )
            }
            <Box my={3}>
              <Typography variant="subtitle1" component="p">
                Cities
              </Typography>
              <Link target="_blank" to='https://losangeles.moorhousecoating.com' rel="noopener">
                <Typography variant="subtitle2" component="p" color="textPrimary">
                  Los Angeles
                </Typography>
              </Link>
              <Link target="_blank" to='https://irvine.moorhousecoating.com' rel="noopener">
                <Typography variant="subtitle2" component="p" color="textPrimary">
                  Irvine
                </Typography>
              </Link>
              <Link target="_blank" to='https://palmdesert.moorhousecoating.com' rel="noopener">
                <Typography variant="subtitle2" component="p" color="textPrimary">
                  Palm Desert
                </Typography>
              </Link>
              <Link target="_blank" to='https://scottsdale.moorhousecoating.com' rel="noopener">
                <Typography variant="subtitle2" component="p" color="textPrimary">
                  Scottsdale
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item md={8} sm={6} xl={8} xs={12}>
            <Iframe iframe={iframe} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Contact